import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d763d52c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("img", {
      class: _normalizeClass({ 'background-image': true, 'is-active': _ctx.currentImage == 0 }),
      src: _ctx.image1
    }, null, 10, _hoisted_1),
    _createElementVNode("img", {
      class: _normalizeClass({ 'background-image': true, 'is-active': _ctx.currentImage == 1 }),
      src: _ctx.image2
    }, null, 10, _hoisted_2)
  ], 64))
}