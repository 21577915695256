
import { Options, Vue } from "vue-class-component";
@Options({
  data() {
    return {
      images: [
        "/assets/images/IMG_3576.JPG",
        "/assets/images/IMG_3578.JPG",
        "/assets/images/IMG_3582.JPG",
        "/assets/images/IMG_3588.JPG",
        "/assets/images/IMG_3593.JPG",
        "/assets/images/IMG_3596.JPG",
        "/assets/images/IMG_3597.JPG",
        "/assets/images/IMG_3598.JPG",
        "/assets/images/IMG_3599.JPG",
        "/assets/images/IMG_3600.JPG",
        "/assets/images/IMG_3601.JPG",
        "/assets/images/IMG_3602.JPG",
        "/assets/images/GruenGruen.JPG",
        "/assets/images/IMG_3591.JPG",
        "/assets/images/IMG_3612.JPG",
        "/assets/images/Mandelbluete1.JPG",
        "/assets/images/Mandelbluete3.JPG",
        "/assets/images/Mariendistel.JPG",
        "/assets/images/Spinnentau.JPG",
      ],
      imageElements: [],
      currentImage: 0,
      image1: "",
      image2: "",
    };
  },
  methods: {
    randomIntBetween(min: number, max: number) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
  },
  mounted() {
    this.imageElements = this.images.map(function (image: string) {
      let img = new Image();
      img.src = image;
      return img;
    });
    this.image1 = this.images[this.randomIntBetween(0, this.images.length - 1)];
    this.image2 = this.images[this.randomIntBetween(0, this.images.length - 1)];
    setInterval(() => {
      setTimeout(() => {
        if (this.currentImage == 0) {
          this.image2 =
            this.images[this.randomIntBetween(0, this.images.length - 1)];
        } else {
          this.image1 =
            this.images[this.randomIntBetween(0, this.images.length - 1)];
        }
        this.currentImage = this.currentImage == 1 ? 0 : 1;
      }, 2500);
    }, 5000);
  },
})
export default class Home extends Vue {}
