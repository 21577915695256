
import { Options, Vue } from "vue-class-component";
import BackgroundImages from './components/BackgroundImages.vue'
@Options({
  data() {
    return {
      menuOpen: false,
      routes:[
        {
          to: '/',
          title: 'Home'
        },
        {
          to: '/rkyoga',
          title: 'rkYoga'
        },
        {
          to: '/about',
          title: 'Über mich'
        },
        {
          to: '/contact',
          title: 'Kontakt'
        }
      ]
    };
  },
  components: {BackgroundImages},
})
export default class App extends Vue {}
